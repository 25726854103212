
import { Component, Vue } from 'vue-property-decorator';

@Component({
  layout: 'default',
  metaInfo: {
    title: 'Check-in not found',
    // override the parent template and just use the above title only
  },
})
export default class CheckInNotFound extends Vue {
  // beforeRouteEnter(_: any, from: any, next: any) {
  //   if (from.name !== 'guests-booking-id') {
  //     next('/');
  //   } else {
  //     next();
  //   }
  // }

  get background() {
    // eslint-disable-next-line global-require
    return require('@/assets/images/pages/wave-grey.webp');
  }
}
